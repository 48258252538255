import { Inject, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigUrlService } from './services/config-url/config-url.service';
import * as fileSaver from 'file-saver';
import {SharedConstant} from '../lib/constants/sharedConstants';
import {Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  ClientCount = 0;
  selectedMasterAccnt: any = new BehaviorSubject(null);
  isLoading = new BehaviorSubject(false);
  setRefresh = new EventEmitter<boolean>();
  errorMessage = new BehaviorSubject(false);
  userNameMsg = new BehaviorSubject(false);
  userActionMsg = new BehaviorSubject(false);
  userBrowserMsg = new BehaviorSubject(false);
  userTimeMsg = new BehaviorSubject(false);
  specificClientView = new BehaviorSubject(false);
  currentOrg = new BehaviorSubject(null);
  public clientInformation: any = new BehaviorSubject(null);
  configURL = this.config.setApplicationUrl();
  userAccess: any = {
    isMultiUser: false,
    isAddlink: false,
    isEmtrlink: false
  };
  agreementsInfo = SharedConstant.agreements;
  public userPolicy = new BehaviorSubject(null);
  policy: any;
  errorResponse: any;
  setHistoryResponse = new EventEmitter<any>();
  schErrorMsg = new EventEmitter<any>();
  isValueObject = false;
  isModalOpened = new BehaviorSubject(false);
  showSchError = new EventEmitter<any>();
  checkEntitlement: any = [];
  hasEntitlements: any = {
    hasAddlink: false,
    hasEmtr: false
  };
  addOrEditEmployee: string;
  closeToast: any = false;
  showTC: any = false;
  public parentPage: string;
  public requestStatus: any;
  public requestStatusFilterObj = {
    status: ['All'],
    action: 'All',
    source: ['All'],
    liable407: '',
    fromDate: '',
    toDate: '',
    orgId: ''
  };

  getSharedConstant = SharedConstant;
  validationFields = {
    filterBtnDisableForEmpId: false,
    invalidEmpId: false,
    filterBtnDisableForFN: false,
    invalidFN: false,
    filterBtnDisableForLN: false,
    invalidLN: false,
    filterBtnDisableForacctHolderFN: false,
    invalidacctHolderFN: false,
    filterBtnDisableForacctHolderLN: false,
    invalidacctHolderLN: false,
    filterBtnDisableForGrpName: false,
    invalidGrpName: false,
    filterBtnDisableForMasterAcc: false,
    invalidMasterAcc: false
  };
  requestFailureStatus = new BehaviorSubject(false);
  disableClientSelector: any = new BehaviorSubject(false);
  stateValues = {
    multiSortMeta: '',
    filterObj: '',
    pageObj: {
      pageNo: 0,
      pageSize: 15
    },
    totalElem: '',
    clientObj: {},
    filterClicked: false,
    toggle: false,
    stateManagementFor: '',
    instrumentSearchedKey: ''
  };
  isClientChanged: any = false;
  clientInfo = new BehaviorSubject(null) ;
  tableDropdownVal = new BehaviorSubject(null) ;
  viewHistorytableDropdownVal = new BehaviorSubject(null) ;
  updatedDate = new BehaviorSubject(null);
  applicationInfo: any;
  selectedClientInfo: any = new BehaviorSubject(null);
  emitClientDropdownDisableValue = new EventEmitter<boolean>();
  userEntitlementList: any = {
    workbench: false,
    accountLinking: false,
    addLink: false,
    emtrOrInstGrp: false,
    emtr: false,
    instrumentsGrp: false,
    masterGrp: false,
    uploads: false,
    reports: false,
    admin: false,
    manageClients: false,
    manageUsers: false
  };
  businessUnit: any;
  orgIdValue = 0;
  businessUnitVal: any;
  empListMultiSortMeta: any;
  clientType = new BehaviorSubject(true);
  decodeUrl = true;
  selectedGroupOrPlanName = '';
  clientSummary: any;
  initialClientRecords: any;
  isAccountNumInvalid = false;
  assetGrpCachedData = [];
  presetRuleCachedData = [];
  selectedGroupAndPlanNameInEmpGroup = '';
  selectedGroupAndPlanIdInEmpGroup = '';
  navFromGrpOrPlan = false;
  groupType = '';
  userPolicyEntitlementList = [];
  userBUList = [];
  getOrgId = 0;
  orgId: any;
  userId: any;
  hashedUserId: any;
  sessionTimeoutValue: any;
  userName: any;
  isDTBtnClicked = false;
  isDTValueUpdated = false;
  isLoadPage = false;
  isLoadPageOnClientChange = false;
  pageName = 'RulesLanding';
  resetInstrumentGroupValue = new BehaviorSubject('');
  deletedGrpOrPlan = false;
  deletedMastGrp = false;
  showRulesDraftSaveSuccessMsg = false;
  isConfirmationRequiredClientChange = false;
  resetFilterForm = new EventEmitter<any>();
  empGrpStep2InitialEmpData: any = [];
  initialEmpSubAcctAssignToRule = [];
  isPreviousBtnClickedOnEdit = false;
  totalEmployeeRecords = { totalSelectedRecords: null, allEmployeeRecords: null };

  constructor(@Inject('env') public env, private httpService: HttpClient, public config: ConfigUrlService, @Inject('entitlementConst') public entitlementConst,
              private router: Router) {
  }

  uploadFile(postData: any) {
    let schwabClientHeader = [];
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, this.getOrgId, schwabClientHeader);
    const upload = this.configURL.bffBatchUploadExportEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    return this.httpService.post(upload, postData);
  }

  public uploadFileAddlink(uploadEndPt: any, postData: any): Observable<any> {
    const url = this.configURL.linkageEndPoint + uploadEndPt;
    return this.httpService.post(url, postData);
  }

  requestListProcessing(uploadEndPt: any, type: any, param): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, this.getOrgId, schwabClientHeader);
    let url = '';
    if (this.getOrgId && this.getOrgId !== 0) {
      url = this.configURL.bffBatchUploadEndpointV1.replace('PassThrough?', `PassThrough/${uploadEndPt}/details?`) + '&status=' + type + '&' + param + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      url = this.configURL.bffBatchUploadEndpointV1.replace('PassThrough?', `PassThrough/${uploadEndPt}/details?`) + '&status=' + type + '&' + param;
    }
    return this.httpService.get(url);
  }

  sendErrorMessage(message) {
    this.errorMessage.next(message);
    this.userNameMsg.next(message);
    this.userActionMsg.next(message);
    this.userBrowserMsg.next(message);
    this.userTimeMsg.next(message);
  }
  clearErrorMessage() {
    const message: any = '';
    this.errorMessage.next(message);
    this.userNameMsg.next(message);
    this.userActionMsg.next(message);
    this.userBrowserMsg.next(message);
    this.userTimeMsg.next(message);
  }

  deleteEmployeeGrp(empGrpId: number, forceDel: boolean) {
    return this.httpService.delete(this.configURL.empGroupEndPoint + '/' + empGrpId + '/' + forceDel);
  }

  deleteEmpAccount(deleteFormData) {
    let schwabClientHeader = [];
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, deleteFormData.orgId, schwabClientHeader);
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.empId, deleteFormData.empId, schwabClientHeader);
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.subAccount, deleteFormData.subAccountNumber, schwabClientHeader);
    return this.httpService.delete(this.configURL.bffEmployeeEndpointV1 + '&forceDelete=' + deleteFormData.forceDelete + '&Schwab-Client-Ids= '  + schwabClientHeader);
  }

   checkParamIsEmpty(paramType, paramValue, schwabClientHeader) {
     if (paramType) {
       schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap[paramType], paramValue, schwabClientHeader);
     }
     return schwabClientHeader;
   }

   getEmployeeList(pageObj, dataObj, groupId: number, ruleId, orgId?): Observable<any> {
    const empAccntFilterInfo = dataObj;
    let serviceOrgId = orgId ? orgId : this.currentOrg.value;
    
    let schwabClientHeader = [];

    let url;
    
    if (this.groupType !== this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, serviceOrgId, schwabClientHeader);
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.type, 'ACCT', schwabClientHeader);
    }
    if (this.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.type, this.getSharedConstant.manageMasterGroups.acctTypeMSTR, schwabClientHeader);
    }
    if (groupId) {
      schwabClientHeader = this.checkParamIsEmpty('grpId', groupId, schwabClientHeader);
    }
    if (ruleId) {
      schwabClientHeader = this.checkParamIsEmpty('ruleId', ruleId, schwabClientHeader);
    }
    const pageNumber = pageObj.pageNo !== undefined ? pageObj.pageNo + 1 : pageObj.pageNumber;
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    if (empAccntFilterInfo) {
      url = this.configURL.bffEmployeeAccountSummaryEndpointV1 + '&pageNumber=' + pageNumber + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      url = this.configURL.bffEmployeeAccountSummaryEndpointV1 + '&pageNumber=' + pageNumber + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
    }
    return this.httpService.post(encodeURI(url), empAccntFilterInfo);
  }

  downloadFileSystem(filename: string, port: string): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, this.getOrgId, schwabClientHeader);
    let httpHeaders = new HttpHeaders();
    let url;
    const bffBaseUrl = this.configURL.bffDownloadFileEndPointV1;
    if (filename === this.agreementsInfo.sposName) {
      httpHeaders = httpHeaders.append('Accept', 'text/pdf; charset=utf-8');
    } else {
      httpHeaders = httpHeaders.append('Accept', 'text/csv; charset=utf-8');
    }

    if (port === 'spos') {
      if (this.getOrgId && this.getOrgId !== 0) {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/TEMPLATE?`) + '&value=' + filename + '&format=.csv' + '&Schwab-Client-Ids= ' + schwabClientHeader;
      } else {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/TEMPLATE?`) + '&value=' + filename + '&format=.csv';
      }
    } else if (port === 'snapshot') {
      if (this.getOrgId && this.getOrgId !== 0) {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/REPORT?`) + '&value=' + filename + '&format=.csv' + '&Schwab-Client-Ids= ' + schwabClientHeader;
      } else {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/REPORT?`) + '&value=' + filename + '&format=.csv';
      }
    } else if (port === 'upload') {
      if (this.getOrgId && this.getOrgId !== 0) {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/UPLOAD?`) + '&value=' + filename + '&format=.csv' + '&Schwab-Client-Ids= ' + schwabClientHeader;
      } else {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/UPLOAD?`) + '&value=' + filename + '&format=.csv';
      }
    } else if (port === 'submittedFile') {
      if (this.getOrgId && this.getOrgId !== 0) {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/FILE?`) + '&value=' + filename + '&format=.csv' + '&Schwab-Client-Ids= ' + schwabClientHeader;
      } else {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/FILE?`) + '&value=' + filename + '&format=.csv';
      }
    } else if (port === 'userGuide') {
      if (this.getOrgId && this.getOrgId !== 0) {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/USERGUIDE?`) + '&value=' + filename + '&format=.pdf' + '&Schwab-Client-Ids= ' + schwabClientHeader;
      } else {
        url = bffBaseUrl.replace('PassThrough?', `PassThrough/USERGUIDE?`) + '&value=' + filename + '&format=.pdf';
      }
    } else {
      if (this.getOrgId && this.getOrgId !== 0) {
        url = this.configURL.bffReportFileEndPointV1.replace('PassThrough?', `PassThrough/${filename}?`) + '&Schwab-Client-Ids= ' + schwabClientHeader;
      } else {
        url = this.configURL.bffReportFileEndPointV1.replace('PassThrough?', `PassThrough/${filename}?`);
      }
    }

    return this.httpService.get(encodeURI(url), {
      headers: httpHeaders,
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  saveFile(data: any, filename: string) {
    let blob: any;
    if (filename !== this.agreementsInfo.fileName) {
      blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    } else {
      blob = new Blob([data], {type: 'application/pdf'});
    }
    fileSaver.saveAs(blob, filename);
  }

  populateEmpAccounts(employees, selectedArr, selectedEmpAccounts, empId, subAccntNumber) {
    console.log('--------------------------IN PopulateEmpAccounts - Shared service--------------------')
    // console.log('employees value in PopulateEmpAccounts : ', employees)
    // console.log('selectedArr value in PopulateEmpAccounts : ', selectedArr)
    // console.log('selectedEmpAccounts value in PopulateEmpAccounts : ', selectedEmpAccounts)
    // console.log('empId value in PopulateEmpAccounts : ', empId)
    // console.log('subAccntNumber value in PopulateEmpAccounts : ', subAccntNumber)

    // const empIndex = employees.map(emp => emp.data.empId);
    // selectedArr.forEach(selectedEmp => {
    //   if (selectedEmp.data.empId.indexOf('null') === -1) {
    //     let pushSubAcc = true;
    //     if (empIndex.indexOf(selectedEmp.data.empId) !== -1) {
    //       if (employees[empIndex.indexOf(selectedEmp.data.empId)].children.length <= selectedEmp.children.length) {
    //         selectedEmpAccounts.push(employees[empIndex.indexOf(selectedEmp.data.empId)]);
    //         empId.push(selectedEmp.data.empId);
    //         pushSubAcc = false;
    //       }
    //       selectedEmp.children.forEach(account => {
    //         account.parent = selectedEmp;
    //         selectedEmpAccounts.push(account);
    //         if (pushSubAcc) {
    //           subAccntNumber.push(account.data.subAcctNum);
    //         }
    //       });
    //     }
    //   } else {
    //     selectedEmp.children.forEach(account => {
    //       account.parent = selectedEmp;
    //       selectedEmpAccounts.push(account);
    //       subAccntNumber.push(account.data.subAcctNum);
    //     });
    //   }
    // });

    /*const empIndex = employees.map(emp => emp.empId && emp.subAcctNum);
    selectedArr.forEach(selectedEmp => {
      if (selectedEmp.empId) {
        if (empIndex.indexOf(selectedEmp.empId && selectedEmp.subAcctNum) !== -1) {
          console.log('empIndex value - ', empIndex)
          console.log('Index value - ', empIndex.indexOf(selectedEmp.empId && selectedEmp.subAcctNum))
          console.log('Index value of employees - ', employees[empIndex.indexOf(selectedEmp.subAcctNum)])
          if (employees[empIndex.indexOf(selectedEmp.subAcctNum)]) {
            console.log('Inside second if block shared service ')
            console.log('value pushed for selectedEmpAccounts - ', employees[empIndex.indexOf(selectedEmp.empId && selectedEmp.subAcctNum)])
            selectedEmpAccounts.push(employees[empIndex.indexOf(selectedEmp.empId && selectedEmp.subAcctNum)]);
            empId.push(selectedEmp.empId);
          }
        } else {
          console.log('Inside 1st ELSE block shared service ')
          subAccntNumber.push(selectedEmp.subAcctNum);
        }
      } else {
        console.log('Inside 2nd ELSE block shared service ')
        subAccntNumber.push(selectedEmp.subAcctNum);
      }
    });*/


    selectedArr.forEach(selectedEmp => {
      if (selectedEmp.empId) {
        const empIndex = employees.map(emp => emp.empId && emp.subAcctNum).indexOf(selectedEmp.empId && selectedEmp.subAcctNum);
        if (empIndex !== -1) {
          console.log('Inside if block shared service ')
          console.log('selectedEmp value - ', selectedEmp)
          console.log('Index value - ', empIndex)
          console.log('Index value of employees - ', employees[empIndex])
          // if (employees[empIndex.indexOf(selectedEmp.subAcctNum)]) {
          //   console.log('value pushed for selectedEmpAccounts - ', employees[employees.indexOf(selectedEmp.empId && selectedEmp.subAcctNum)])
            selectedEmpAccounts.push(employees[empIndex]);
          !empId.includes(selectedEmp.empId) ? empId.push(selectedEmp.empId) : '';
          // }
        } else {
          console.log('Inside 1st ELSE block shared service ')
          subAccntNumber.push(selectedEmp.subAcctNum);
        }
      } else {
        console.log('Inside 2nd ELSE block shared service ')
        subAccntNumber.push(selectedEmp.subAcctNum);
      }
    });

    const emitData = {
      empIds: empId,
      subAccntNumbers: subAccntNumber,
      selectedArr: selectedEmpAccounts
    };
    return emitData;
  }

  validateUserRole(userRole) {
    if (userRole.indexOf(1) > -1 && userRole.indexOf(5) > -1) {
      this.userAccess.isMultiUser = true;
    } else if (userRole.indexOf(5) > -1) {
      this.userAccess.isEmtrlink = true;
    } else {
      this.userAccess.isAddlink = true;
    }
    return this.userAccess;
  }

  usersPolicy(): Observable<any> {
    return this.httpService.get(this.configURL.bffUserProfileEndPointV1);
  }

  checkEntitlements(a, b, c) {
    const addlinkPolicy = this.userPolicyEntitlementList;
    const clientPolicy = this.policy && this.policy.data ? this.policy.data.userPolicy : {};
    /* Fetch BU or Client specific entitlement from clientPolicy */
    const entitlementList = clientPolicy.buPolicy && clientPolicy.buPolicy.length > 0 ? clientPolicy.buPolicy : clientPolicy.clientPolicy;
    const clientInfo = this.selectedClientInfo.getValue();
    let entitlementListArr = [];

    /* To assign client/BU specific access when client is selected-> Entitlements fetched from clientPolicy */
    if (clientInfo) {
      if (clientPolicy.buPolicy && clientPolicy.buPolicy.length > 0) {
        entitlementListArr = entitlementList.filter(data => {
          return data.businessUnit === clientInfo.businessUnit;
        });
      } else {
        if (entitlementList && entitlementList.length > 0) {
          entitlementListArr = entitlementList.filter(data => {
            return data.clientId === clientInfo.clientId;
          });
        }
      }
      if (entitlementListArr.length > 0) {
        for (const entitlement of entitlementListArr[0].entitlementList) {
          if (entitlement.uuid === a || entitlement.uuid === b || entitlement.uuid === c) {
            return true;
          }
        }
      }
    } else if (addlinkPolicy !== null) {
      /* Assign access when All is selected -> Entitlements fetched from userPolicy */
      for (const i of addlinkPolicy) {
        if (i.uuid === a || i.uuid === b || i.uuid === c) {
          return true;
        }
      }
    }
  }

  setUserID(userid) {
    this.userId = userid;
  }

  setHashedUserID(hashedUserid) {
    this.hashedUserId = hashedUserid;
  }

  getUserId() {
      return this.userId;
  }

  getHashedUserId() {
    return this.hashedUserId;
}

  setPolicyResponse(res, type) {
    type === 'success' ? this.policy = res : this.errorResponse = res;
  }

  getUserPolicyEntitlementList() {
    this.userPolicyEntitlementList = [];
    const userPolicy = this.policy.data.userPolicy.buPolicy ? this.policy.data.userPolicy.buPolicy : this.policy.data.userPolicy.clientPolicy;
    const entitlementList = [];
    userPolicy.forEach(bu => {
      bu.entitlementList.filter(uuid => this.userPolicyEntitlementList.findIndex(uuidVal => uuidVal.uuid === uuid.uuid) === -1 ? entitlementList.push(uuid) : '');
      this.userPolicyEntitlementList = entitlementList;
    });
  }

  getBusinessUnitList() {
    this.userBUList = [];
    const policy = this.policy.data.userPolicy.buPolicy ? this.policy.data.userPolicy.buPolicy : this.policy.data.clients;
    policy.forEach(obj => {
      const index = this.userBUList.findIndex(bu => bu === obj.businessUnit) === -1 ? this.userBUList.push(obj.businessUnit) : '';
    });
  }

  setReleaseResponse(res) {
    this.applicationInfo = res;
  }

  getPolicyResponse(type) {
    if (type === 'success') {
      return this.policy;
    } else {
      return this.errorResponse;
    }
  }

  createSortField(pageObj) {
    return (pageObj.sortField) ? '&sortField=' + pageObj.sortField : '';
  }

  callApiHistory(tab, ruleId, pageObj) {
    const sortField = this.createSortField(pageObj);
    const queryString = (tab === 'empgroup') ? '&groupId=' : '&ruleId=';

    if (tab === 'empaccount') {
      let schwabClientHeader = [];
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.subAccount, ruleId.subAcctNum, schwabClientHeader);
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.empId, ruleId.empId === '' ? null : ruleId.empId, schwabClientHeader);
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, pageObj.orgId, schwabClientHeader);
      return this.httpService.get(this.configURL.bffAccountHistoryEndPointV1 + '&orgId=' + pageObj.orgId + '&pageNumber=' + pageObj.pageNo + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids=' + schwabClientHeader);
    } else if (tab === 'instGroup') {
      const url = this.configURL.bffInstGroupHistoryEndPointV1;
      return this.httpService.get(url + '&id=' + ruleId + '&pageNumber=' + pageObj.pageNo + '&pageSize=' + pageObj.pageSize + sortField);
    } else {
      let schwabClientHeader = [];
      if (this.groupType !== this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, pageObj.orgId, schwabClientHeader);
      }
      const url = (tab === 'rule') ? this.configURL.bffRuleHistoryEndPointV1 : (tab === 'instGroup') ? this.configURL.bffInstGroupHistoryEndPointV1 : this.configURL.bffEmployeeGroupHistoryEndPointV1;
      return this.httpService.get(url + '&orgId=' + pageObj.orgId + queryString + ruleId + '&pageNumber=' + pageObj.pageNo + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids=' + schwabClientHeader);
    }
  }

  loadViewHistory(getOrgId, empData, type, viewHistoryData) {
    const queryParam = {
      pageNo : 0,
      pageSize: 15,
      sortField: '',
      orgId: getOrgId
    };
    if (type === 'rules') {
      delete queryParam.orgId;
    }
    const historyId = (type === 'empaccount') ? empData : empData.id;
    this.callApiHistory(type, historyId, queryParam).subscribe((History: any) => {
      this.setHistoryResponse.emit(History);
      viewHistoryData.openHistoryModal(History.message, type, historyId);
    });
  }

  getHistoryPaginatedValues(id, type, data) {
    this.callApiHistory(type, id, data).subscribe((History: any) => {
      this.setHistoryResponse.emit(History);
    });
  }

  checkUserEntitlements(entitlementList) {
    this.checkEntitlement = [];
    /**************
     * match the uuid for emtr and addlink based on Rules tab and link tab uuid respectively.
     * based on match we push the entitlement name in an array
     * *************/
    const clientInfo = this.selectedClientInfo.getValue();
    if (clientInfo) {
      for (const i of entitlementList) {
        /* To assign client/BU specific access when client is selected -> Entitlements fetched from clientPolicy */
        if ((i.clientId && clientInfo.clientId === i.clientId) || (i.businessUnit && clientInfo.businessUnit === i.businessUnit)) {
          for (const entitlement of i.entitlementList) {
            if (entitlement.uuid === this.entitlementConst.navUUID.addLinkAccess.uuid) {
              this.checkEntitlement.push(entitlement.entitlementName);
            } else if (entitlement.uuid === this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.uuid) {
              this.checkEntitlement.push(entitlement.entitlementName);
            }
          }
          break;
        }
      }
    } else {
      /* Assign access when All is selected -> Entitlements fetched from userPolicy */
      for (const entitlement of entitlementList) {
        if (entitlement.uuid === this.entitlementConst.navUUID.addLinkAccess.uuid) {
          this.checkEntitlement.push(entitlement.entitlementName);
        } else if (entitlement.uuid === this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.uuid) {
          this.checkEntitlement.push(entitlement.entitlementName);
        }
      }
    }

    if (this.checkEntitlement.indexOf('ADDLINK_APP') > -1 && this.checkEntitlement.indexOf('EMTR_APP') > -1) {
      this.hasEntitlements = {
        hasAddlink: true,
        hasEmtr: true
      };
    } else if (this.checkEntitlement.indexOf('ADDLINK_APP') > -1) {
      this.hasEntitlements = {
        hasAddlink: true,
        hasEmtr: false
      };
    } else if (this.checkEntitlement.indexOf('EMTR_APP') > -1) {
      this.hasEntitlements = {
        hasAddlink: false,
        hasEmtr: true
      };
    } else {
      this.hasEntitlements = {
        hasAddlink: false,
        hasEmtr: false
      };
    }

    return this.hasEntitlements;
  }

  isInternalApp() {
    const hostname = window.location.hostname;
    const chkHostname = new RegExp('(?=\\S*[\'-])([a-zA-Z\'-]+int)|(localhost)');
    return chkHostname.test(hostname);
  }

  showLoader() {
    this.isLoading.next(true);
  }

  hideLoader() {
    this.isLoading.next(false);
  }

  getReleaseVersion(): Observable<any> {
    const url = this.configURL.bffApplicationDataEndPointV1;
    return this.httpService.get(url);
  }

  getHideUnauthorizedFlag() {
    return localStorage.getItem('hideUnauthorized');
  }

  setHideUnauthorizedFlag(value) {
    this.requestFailureStatus.next(value);
    localStorage.setItem('hideUnauthorized', value);
  }

  removeHideUnauthorizedFlag() {
    localStorage.removeItem('hideUnauthorized');
  }

  isValidOrInvalid(value, inputField, form) {
    let invalidValue;
    if (value && form.get(inputField).invalid) {
      invalidValue = true;
    } else {
      invalidValue = false;
    }
    return invalidValue;
  }

  isValueLengthGreaterThan2(value) {
    let returnVal;
    if (value && value.length < 2) {
      returnVal = true;
    } else {
      returnVal = false;
    }
    return returnVal;
  }

  isValueLengthLessThan9(value) {
    let returnVal;
    if (value && value.length < 9) {
      returnVal = true;
    } else {
      returnVal = false;
    }
    return returnVal;
  }

  setErrorMessage(isAccNumInvalid, errMsg) {
    if (isAccNumInvalid) {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.accountNumberShouldContain8Digits) === -1) {
        errMsg.push(this.getSharedConstant.textFieldErrorMsg.accountNumberShouldContain8Digits);
      }
    } else {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.accountNumberShouldContain8Digits) !== -1) {
        errMsg.splice(errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.accountNumberShouldContain8Digits), 1);
      }
    }

    if (this.validationFields.invalidFN || this.validationFields.invalidLN || this.validationFields.invalidacctHolderFN || this.validationFields.invalidacctHolderLN || this.validationFields.invalidGrpName || this.validationFields.invalidMasterAcc) {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForName) === -1) {
        errMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForName);
      }
    } else {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForName) !== -1) {
        errMsg.splice(errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForName), 1);
      }
    }

    if (this.validationFields.invalidEmpId) {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.employeeIdErrorMsg) === -1) {
        errMsg.push(this.getSharedConstant.textFieldErrorMsg.employeeIdErrorMsg);
      }
    } else {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.employeeIdErrorMsg) !== -1) {
        errMsg.splice(errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.employeeIdErrorMsg), 1);
      }
    }

    /* eslint-disable-next-line max-len */
    if (this.validationFields.filterBtnDisableForEmpId || this.validationFields.filterBtnDisableForFN || this.validationFields.filterBtnDisableForLN || this.validationFields.filterBtnDisableForacctHolderFN || this.validationFields.filterBtnDisableForacctHolderLN || this.validationFields.filterBtnDisableForGrpName || this.validationFields.filterBtnDisableForMasterAcc) {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg) === -1) {
        errMsg.push(this.getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg);
      }
    } else {
      if (errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg) !== -1) {
        errMsg.splice(errMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg), 1);
      }
    }

    this.schErrorMsg.emit(errMsg);
  }

  clearValidationFields() {
    this.validationFields = {
      filterBtnDisableForEmpId: false,
      invalidEmpId: false,
      filterBtnDisableForFN: false,
      invalidFN: false,
      filterBtnDisableForLN: false,
      invalidLN: false,
      filterBtnDisableForacctHolderFN: false,
      invalidacctHolderFN: false,
      filterBtnDisableForacctHolderLN: false,
      invalidacctHolderLN: false,
      filterBtnDisableForGrpName: false,
      invalidGrpName: false,
      filterBtnDisableForMasterAcc: false,
      invalidMasterAcc: false
    };
    this.schErrorMsg.emit('');
  }

  public setMultiSelectDefaultLabel(elementRef, formGrpName, formCntrlName, length) {
    if (elementRef) {
      if (formGrpName.get(formCntrlName).value.length === 0) {
        elementRef.innerText = '';
      } else if (formGrpName.get(formCntrlName).value.length === 1) {
        if (this.isValueObject) {
          if (formCntrlName === 'status') {
            elementRef.innerText = formGrpName.get(formCntrlName).value[0];
          } else if (formCntrlName === 'selectedRuleType' || formCntrlName === 'selectedAppliesTo' || formCntrlName === 'selectedRuleStatus') {
            elementRef.innerText = formGrpName.get(formCntrlName).value[0].name;
          } else {
            elementRef.innerText = formGrpName.get(formCntrlName).value[0].roleDesc;
          }
        } else {
          elementRef.innerText = formGrpName.get(formCntrlName).value;
          elementRef.classList.remove('p-multiselect-label-empty');
        }
      } else if (formGrpName.get(formCntrlName).value.length === length) {
        elementRef.innerText = 'All';
      } else {
        elementRef.innerText = formGrpName.get(formCntrlName).value.length + ' items selected';
      }
    }
  }

  /* Created a common Function to get the selectedClient is '10b5-1' client to use in addlink-empGroup*/
  get10b5ClientDetails() {
    const is10b5Client = (JSON.parse(localStorage.getItem('client'))).tenB51;
    return is10b5Client;
  }

  /* common Function to set 'planId' validators */
  set10b5Validators(form, planIDTxt) {
    if (form.get('enable10b5Checkbox').value) {
      form.controls[planIDTxt].setValidators(Validators.required);
      form.controls[planIDTxt].updateValueAndValidity();
    } else {
      form.controls[planIDTxt].clearValidators();
      form.controls[planIDTxt].updateValueAndValidity();
      form.patchValue({
        planId: ''
      });
    }
  }

  isNumberOnly(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getEmpGroupData(groupId: number) {
    const clientInfo = this.selectedClientInfo.getValue();
    let schwabClientHeader = [];
    schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.grpId, groupId, schwabClientHeader);
    if (clientInfo && clientInfo.orgId > 0) {
      schwabClientHeader = this.setSchwabClientHeaders(this.applicationInfo.data.queryParamMap.orgId, clientInfo.orgId, schwabClientHeader);
    }
    return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&Schwab-Client-Ids=' + schwabClientHeader);
  }

  removeEmptyCheckbox(el, type) {
    const wholeArray = el.nativeElement.querySelectorAll('tr');
    wholeArray.forEach(element => {
      const localData = ((type === 'employees') ? element.children[6] : element.children[2]);
      const firstChild = element.children[1];
      if (localData && !localData.querySelector('.p-highlight') && firstChild && firstChild.querySelector('.check-disabled')) {
        const entireCheckedArray = element.querySelector('.p-checkbox-box');
        if (entireCheckedArray) {
          entireCheckedArray.classList.remove('pi-check');
        }
      }
    });
  }

  removeActionsForRules(moduleType, empGrpTblHeader) {
    /* Removed Action Column in rules Step3 & Step 4*/
    const empGrpTblHeaderDup = JSON.parse(JSON.stringify(empGrpTblHeader));
    if (moduleType !== 'EmployeeGroup') {
      empGrpTblHeaderDup.forEach((columns, index) => {
        if (columns.field === 'actions') {
          empGrpTblHeaderDup.splice(index, 1);
        }
      });
    }
    return empGrpTblHeaderDup;
  }

  setStateVal(stateVal) {
    sessionStorage.setItem('stateVal', stateVal);
  }

  getStateVal() {
    return JSON.parse(sessionStorage.getItem('stateVal'));
  }

  clearLocalStorage() {
    sessionStorage.removeItem('stateVal');
  }

  setSelectionItem(wholeArray) {
    const empIds = [];
    const subAccntNumbers = [];
    if (wholeArray && wholeArray.length > 0) {
      if (this.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        wholeArray.forEach(val => {
          const finalSubAccnt = {
            empId: '',
            subAcctNum: val.subAcctNum ? val.subAcctNum : val
          };
          subAccntNumbers.push(finalSubAccnt);
        });
      } else {
        // wholeArray.forEach(val => {
        //   if (val.children && val.data.empId.indexOf('null') === -1) {
        //     empIds.indexOf(val.data.empId) === -1 ? empIds.push(val.data.empId) : empIds.splice(empIds.indexOf(val.data.empId), 1);
        //     val.children.forEach(record => {
        //       wholeArray = wholeArray.filter(item => item.data.empId !== record.data.empId);
        //     });
        //   }
        // });

        // wholeArray.forEach(val => {
        //   if (val.parent && val.parent !== null) {
        //     const checkNullEmpId = (val.parent.data.empId.indexOf('null') === -1) ? val.parent.data.empId : '';
        //     const finalSubAccnt = {
        //       empId: checkNullEmpId,
        //       subAcctNum: val.data.subAcctNum
        //     };
        //     subAccntNumbers.push(finalSubAccnt);
        //   }
        // });
        // wholeArray.forEach(val => {
        //   if (val && val.empId) {
        //     (empIds.indexOf(val.empId) === -1 && val.parent) ? empIds.push(val.empId) : empIds.indexOf(val.empId) > -1 ? empIds.splice(empIds.indexOf(val.empId), 1) : '';
            // val.forEach(record => {
            //   wholeArray = wholeArray.filter(item => item.empId !== record.empId);
            // });
        //   }
        // });

        wholeArray.forEach(val => {
          if (val && val !== null) {
            const checkNullEmpId = (val.empId) ? val.empId : '';
            if (val.child && !val.parent) {
              const finalSubAccnt = {
                empId: checkNullEmpId,
                subAcctNum: val.subAcctNum
              };
              subAccntNumbers.push(finalSubAccnt);
            }
          }
        });
      }
    }
    const finalArr = {
      empIds,
      subAccntNumbers
    };
    return finalArr;
  }

  removeDuplicates(selectedArr) {
    if (selectedArr && selectedArr.length > 0) {
      selectedArr = selectedArr.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i);
    }
    return selectedArr;
  }

  formData(arr1, arr2, param) {
    let finalValue;
    if (arr1 && arr2 && arr1[param] !== undefined && arr2[param] !== undefined) {
      finalValue = arr1[param].concat(arr2[param]);
    } else if (arr1 === undefined && arr2 && arr2[param] !== undefined) {
      finalValue = arr2[param];
    } else if (arr1 && arr1[param] !== undefined && arr2 === undefined) {
      finalValue = arr1[param];
    } else {
      finalValue = [];
    }
    return finalValue;
  }

  setSchwabClientHeaders(keys, value, schwabClientHeader) {
    schwabClientHeader.push(`${keys}:${value}`);
    return schwabClientHeader;
  }

  validateNamePlanIdResponse(res, is10b5Client, groupName, planId, getConstant, type) {
    let errorMsg;
    /* For 105-1 Client if both empGrpName and planId is Duplicate */
    const planIds = (typeof planId === 'number') ? JSON.stringify(planId) : planId;
    if (is10b5Client && groupName === res.data.name && planIds === JSON.stringify(res.data.planId)) {
      errorMsg = (type === 'EmpGrp') ? getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId : getConstant.rule10b5Msg.duplicateRuleNameAndPlanId;
    } else if (is10b5Client && groupName !== res.data.name && planIds === JSON.stringify(res.data.planId)) { /* For 105-1 Client only planId is Duplicate */
      errorMsg = (type === 'EmpGrp') ? getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdExitsTxt : getConstant.rule10b5Msg.duplicatePlanId;
    } else {
      const bu = this.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.getSharedConstant.manageMasterGroups.groupTypeMGP : this.businessUnit;
      /* eslint-disable-next-line max-len */
      errorMsg = (type === 'EmpGrp') ? this.getSharedConstant[bu].empGrpOrPlanExistTxt : getConstant.rule10b5Msg.duplicateRuleName; /* For both Normal & 105-1 Client only empGrpName is Duplicate */
    }
    return errorMsg;
  }

  setResponseMessage(res, is10b5Client, name, planId, getConstant, type) {
    const validEmpGrpTxt = 'validEmpGroup';
    const messageTxt = 'message';
    const resposeValidation = {
      data : {
        validEmpGroup: false,
        message: ''
      }
    };
    if (res.data !== null) {
      resposeValidation.data[validEmpGrpTxt] = false;
      resposeValidation.data[messageTxt] = this.validateNamePlanIdResponse(res, is10b5Client, name, planId, getConstant, type);
    } else {
      resposeValidation.data[validEmpGrpTxt] = true;
      resposeValidation.data[messageTxt] = (type === 'Rules') ? getConstant.rule10b5Msg.ruleNameNotExists : '';
    }
    return resposeValidation;
  }

  checkAccess(accessName): boolean {
    return this.userEntitlementList[accessName];
  }

  totalElementsWithCommas(ele) {
    return ele.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  searchTextInAutoComplete(searchtxt) {
    searchtxt = searchtxt.replaceAll('+', '\\+');
    searchtxt = searchtxt.replaceAll('(', '\\(');
    searchtxt = searchtxt.replaceAll(')', '\\)');
    searchtxt = searchtxt.replaceAll('[', '\\[');
    searchtxt = searchtxt.replaceAll(']', '\\]');
    searchtxt = searchtxt.replaceAll('*', '\\*');
    searchtxt = searchtxt.replaceAll('?', '\\?');
    return searchtxt;
  }

  savePdfFile(data: any, filename: string) {
    const blob = new Blob([data], {type: 'application/pdf'});
    fileSaver.saveAs(blob, filename);
  }

  loadDataTrackValue(routerLink) {
    if (routerLink === 'emtr') {
      routerLink = 'rules';
    } else if (routerLink === 'addlink-upload') {
      /* Replacing the '-' present in the routerlLink with '_', eg: addlink-upload to addlink_upload */
      routerLink = routerLink.split('-').join('_');
    } else if (routerLink === 'reports-list') {
      routerLink = 'reports';
    }
    /* Removing the '-' present in the routerlLink by replacing it with '', eg: request-list to requestlist */
    routerLink = routerLink.split('-').join('');
    if (routerLink === 'mastergroups' || routerLink === 'plans') {
      routerLink = 'manage' + routerLink;
    }
    const gutTrack = this.isDTBtnClicked ? 'linkid-lc_' + routerLink : 'linkid-nav_' + routerLink;
    this.gutTracking(gutTrack.trim().toLowerCase());
    this.isDTBtnClicked = false;
    /* Set isDTValueUpdated value to true, which will let the call of pageLoad method on navigating to respective screen. */
    this.isDTValueUpdated = true;
  }

  loadSpsPageCall() {
    this.isLoadPageOnClientChange = false;
    let pageLoadVal = this.router.url.slice(this.router.url.lastIndexOf('/') + 1);
    if (this.router.url.indexOf('emtr') > 0 || this.router.url.indexOf('manage-clients') > 0 || this.router.url.indexOf('uploads-list') > 0 || this.router.url.indexOf('reports-list') > 0) {
      const arr = this.router.url.split('/', -1);
      pageLoadVal = (this.router.url.indexOf('create') > 0 && this.router.url.indexOf('manage-clients') === -1) ? arr[arr.length - 3].split('-').join('_') : arr[arr.length - 2].split('-').join('_');
      if (this.router.url.indexOf('rules-bulk-upload') > 0 || this.router.url.indexOf('emp-bulk-upload') > 0 || this.router.url.indexOf('emp-group-bulk-upload') > 0 || this.router.url.indexOf('emp-plan-bulk-upload') > 0) {
        pageLoadVal = 'bulk_upload_' + pageLoadVal;
      } else if (pageLoadVal === 'master_groups') {
        pageLoadVal = 'manage_' + pageLoadVal;
      }
    } else if (this.router.url.indexOf('request-list') > 0) {
      pageLoadVal = pageLoadVal.replace(/[^\w\s]/gi, '');
    } else if (this.router.url.indexOf('manage-users') > 0 && this.router.url.indexOf('create') > 0) {
      const arr = this.router.url.split('/', -1);
      pageLoadVal = arr[arr.length - 2].split('-').join('_');
    } else if (this.router.url.indexOf('addlink-upload') > 0 || this.router.url.indexOf('manage-users') > 0 || this.router.url.indexOf('custom-reports') > 0) {
      pageLoadVal = pageLoadVal.replace(/[^\w\s]/gi, '_');
    } else if (this.router.url.indexOf('preset-reports') > 0) {
      pageLoadVal = 'preset_reports';
    }
    this.spaPageCall(pageLoadVal);
  }

  spaPageCall(pagename) {
    const tms = window && window.tms || {};
    if (tms && typeof tms.trackPage === 'function') {
      setTimeout(tms.trackPage(pagename), 500);
    }
  }

  gutTracking(datatrack) {
    if (window && typeof window.GUTtrack === 'function') {
      window.GUTtrack(datatrack);
    }
  }

  // Clear success msg only. leave the warning msgs
  clearSuccessMsgs(messageService: MessageService, selectionTable?) {
    if (this.showRulesDraftSaveSuccessMsg && selectionTable) {
      messageService.clear();
      this.showRulesDraftSaveSuccessMsg = false;
    } else if (!selectionTable) {
      messageService.clear();
    }
  }

  /* Opens The Modal */
  openModal(id) {
    // @ts-ignore
    let modalElement = document.getElementById(id) as HTMLSdpsModalElement | null;
    if (modalElement) {
      modalElement.showModal();
    }
  }

  /* Closes The Modal */
  public closeModal(id): void {
    // @ts-ignore
    let modalElement = document.getElementById(id) as HTMLSdpsModalElement | null;
    modalElement.hideModal();
  }

  /* Check the employee data changes in employee selection table for employee group creation & rule creation
  and return which employee data added & removed*/
  /* check selected data with initial selected data &  */
  checkChanges(selectedArr = [], initialDataArray = [], empIds, removedArray?, moduleType?) {
    /**
     * selectedArr: Updated selected array with latest employee data
     * initialDataArray: Initial employee Data Array.
     * empIds: intitial empIds
     */
    const extraEmpIds = [];
    const removedEmpIds = [];
    const allEmpIds = [];
    const updatedEmpIds = [...empIds]; /** UpdatedEmpIds array is copy of initial empIds Array. It will be updated based on newly selected data & removed data. */

    /* newly added employee data */
    const extraData = selectedArr.filter(item => {
      /** Pushing All Employee Ids of data which has empIds & not pushing duplicate data */
      (item.empId && !allEmpIds.includes(item.empId)) ? allEmpIds.push(item.empId) : '';
      /** Checking extra data condition */
      if (initialDataArray.findIndex(ele => (ele.empId === item.empId) && (ele.subAcctNum === item.subAcctNum) && ((moduleType !== 'Rules' && (ele.parent === item.parent) && (ele.child === item.child)) || moduleType === 'Rules')) === -1) {
        /** Pushing Employee Ids of newly selected data & not pushing duplicate data */
        (item.empId && !extraEmpIds.includes(item.empId)) ? extraEmpIds.push(item.empId) : '';
        return true;
      }
    });

    /* removed employee data */
    const removedData = initialDataArray.filter(item => {
      if (selectedArr.findIndex(ele => (ele.empId === item.empId) && (ele.subAcctNum === item.subAcctNum) && ((moduleType !== 'Rules' &&  (ele.parent === item.parent) && (ele.child === item.child)) || moduleType === 'Rules')) === -1) {
        /** Pushing Employee Ids of removed data & not pushing duplicate data */
        (item.empId && !removedEmpIds.includes(item.empId)) ? removedEmpIds.push(item.empId) : '';
        /** Pushing Employee Ids of removed data & not pushing duplicate data in updated EmpIds Array*/
        (item.empId && updatedEmpIds.includes(item.empId)) ? updatedEmpIds.splice(updatedEmpIds.indexOf(item.empId), 1) : '';
        return true;
      }
    });
    return { extraEmpIds, allEmpIds, extraData, removedData, removedEmpIds, updatedEmpIds };
  }
}
