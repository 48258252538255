import {Component, OnInit, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EmsSubjectService} from '@core_services/ems-subject.service';
import {HeaderService} from '@core_services/header/header.service';
import {EmtrService} from '@ems/emtr';
import {AddlinkService} from '@ems/addlink';
import {SharedConstant, SharedService} from '@ems/shared';
import {AppService} from '@core_services/app.service';
import {AuthService} from '@core_services/auth/auth.service';
import {LocalStorageService} from '@core_services/local-storage.service';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {TokenService} from '@core_services/token.service';
import {debounceTime} from 'rxjs/operators';
import {Observable, Observer, Subject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {

  clientRecords = [];
  initialClientRecords = [];
  clientObj = {};
  allClientInfo = ShellConstant.allClientInfo;
  sharedConstant = SharedConstant;
  showMenu = {
    navigation: false,
    clientDrpdown: false,
    logout: false
  };
  selectedClient = this.allClientInfo;
  userName: string;
  disableClientDropdown = false;
  userRole: any;
  public innerWidth = 22;
  isSubscribed: any = false;
  @ViewChild('dropdown') dropdown: any;
  scrolledEle: any;
  pageNumber: any = 1;
  pageSize: any = 15;
  loadedCompleteData = false;
  userPolicy: any;
  searchInputUpdate = new Subject<any>();
  clientSearchVal: string;
  isBUClientSelected = false;
  isDropdownOpened = false;
  scrollTopGlobal: number;
  isSearchedEnabled = false;
  globalPageNo = 1;
  searchedDataLoadedComplete = false;
  emptyDisplayMessage: string;
  @ViewChild('confirmationMessageForHeader') confirmationMessage: any;

  constructor(public router: Router, private emsSubject: EmsSubjectService, private headerService: HeaderService, private el: ElementRef,
              private emtrService: EmtrService, private addlinkServ: AddlinkService, public sharedService: SharedService,
              private shellService: AppService, public authService: AuthService, private activeRoute: ActivatedRoute, private localStrService: LocalStorageService, private tokenService: TokenService) {
    this.sharedService.disableClientSelector.subscribe(value => {
      this.disableClientDropdown = value;
    });
  }

  ngOnDestroy() {
    this.sharedService.selectedClientInfo.next(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.charLimitResize(window.innerWidth);
  }

  charLimitResize(innerWidth) {
    if (innerWidth <= 1348) {
      this.innerWidth = 18;
    } else {
      this.innerWidth = 22;
    }
  }

  ngOnInit() {
    this.charLimitResize(window.innerWidth);
    if (this.localStrService.getToken() !== null) {
      const policyResponse = this.sharedService.getPolicyResponse('success');
      if (policyResponse && policyResponse.data) {
        this.showMenu = {
          navigation: true,
          clientDrpdown: true,
          logout: !this.sharedService.isInternalApp()
        };
        this.userPolicy = policyResponse.data.userPolicy;
        this.fetchClientProfile();
        this.userName = policyResponse.data.firstName + ' ' + policyResponse.data.lastName;
        this.authService.userAuthentication.next(true);
        this.authService.setUserPolicy(policyResponse.data.userPolicy);
        this.authService.setUsertype(policyResponse.data.userType);
        this.authService.setClientPolicy(policyResponse.data.clientPolicy);
        this.authService.clientPolicy.next(policyResponse.data.clientPolicy);
        this.authService.userPolicy.next(policyResponse.data.userPolicy);
        this.addlinkServ.userPolicy.next(this.authService.getUserPolicy());
        this.emtrService.userPolicy.next(this.authService.getUserPolicy());
        this.sharedService.userPolicy.next(this.authService.getUserPolicy());
        this.addlinkServ.userType.next(this.authService.getUsertype());

        if (!this.showMenu.navigation) {
          this.router.navigate(['/unauthorized']);
        }
      } else {
        const error = this.sharedService.getPolicyResponse('error');
        if (error && error.status === 500) {
          this.authService.userAuthentication.next(true);
          localStorage.clear();
          this.router.navigate(['/unauthorized']);
        }
      }
    }

    this.headerService.emitClient.subscribe(value => {
      this.isSubscribed = true;
      this.fetchClientProfile(true);
    });

    /* To disable the client droupdown in the header and set the client droupdown value to all */
    this.sharedService.emitClientDropdownDisableValue.subscribe(value => {
      this.disableClientDropdown = value;
      if (this.sharedService.ClientCount !== this.sharedConstant.clientCount) {
        /* Setting the selectedClientInfo to null, when global client selector is set to All */
        this.sharedService.selectedClientInfo.next('');
        this.selectedClient = this.allClientInfo;
      }
      this.dropdown ? this.dropdown.value = this.selectedClient: '';
      this.localStrService.setProfile(JSON.stringify(this.selectedClient));
      this.setClientProfile(this.selectedClient);
      if (value && this.sharedService.clientSummary) {
        this.clientRecords = this.sharedService.clientSummary;
        this.clientRecords = [this.allClientInfo].concat(this.clientRecords);
      }
    });
    this.searchInputUpdate
      .pipe(debounceTime(2000))
      .subscribe((results) => {
        if (this.userPolicy.buPolicy && this.userPolicy.buPolicy.length > 0 && !this.loadedCompleteData) {
          this.clientSearchVal = encodeURIComponent(results);
          this.autoCompleteSearch();
        }
      });
  }
  onDropdownItemShow() {
    this.isDropdownOpened = true;  // to check if user leave without selecting
    // check if 'ALL' value is there or not, If not push
    if ( this.clientRecords.length > 0 ) {
      if (this.clientRecords[0].clientName !== 'All') {
        this.clientRecords.unshift(this.allClientInfo);
        this.clientRecords = this.removeDuplicateClients(this.clientRecords);
      }
      this.dropdown.options = this.clientRecords;
    } else {
      this.fetchClientProfile();
    }
    this.scrollToIndex(0);
    this.attachScrollEvent();
  }
  scrollToIndex(index) {
    setTimeout(() => {
      if (this.dropdown && this.dropdown.viewPort) {
        this.dropdown.viewPort.scrollToIndex(index);
      }
    }, 0);
  }
  attachScrollEvent() {
    setTimeout(() => {
      const panel = this.dropdown.containerViewChild.nativeElement.querySelector('.p-dropdown-panel > .p-dropdown-items-wrapper > p-scroller > .p-scroller');
      if (panel) {
        panel.addEventListener('scroll', event => this.loadNextSetOfData(event));
      }
    }, 0);
  }
  loadNextSetOfData(event?) {
    this.scrolledEle = event.target;
    // calculate if scroll bottom
    const scrollTo = this.scrolledEle.scrollHeight - this.scrolledEle.offsetHeight;
    if ((this.scrollTopGlobal !== scrollTo) && scrollTo === Math.trunc(this.scrolledEle.scrollTop) && !this.loadedCompleteData) {
      this.pageNumber += 1;
      this.fetchNextSetClientProfile(this.pageNumber);
      this.scrollTopGlobal = scrollTo;
    } else if ((this.scrollTopGlobal !== scrollTo) && (scrollTo === (Math.trunc(this.scrolledEle.scrollTop) - 1)) && !this.loadedCompleteData) {
      this.pageNumber += 1;
      this.fetchNextSetClientProfile(this.pageNumber);
      this.scrollTopGlobal = scrollTo;
    } else if ((this.scrollTopGlobal !== scrollTo) && (scrollTo === (Math.trunc(this.scrolledEle.scrollTop) + 1)) && !this.loadedCompleteData) {
      this.pageNumber += 1;
      this.fetchNextSetClientProfile(this.pageNumber);
      this.scrollTopGlobal = scrollTo;
    }
  }
  searchedText(event) {
    this.isSearchedEnabled = true;
    const panel = this.dropdown.containerViewChild.nativeElement.querySelector('.p-dropdown-panel > .p-dropdown-items-wrapper > p-scroller > .p-scroller > ul');
    panel.style.transform = 'translate3d(0px, 0px, 0px)';
    this.emptyDisplayMessage = this.userPolicy.buPolicy && this.userPolicy.buPolicy.length > 0 ? this.sharedConstant.emptyRecordsMessage : this.sharedConstant.noRecords;
    this.searchInputUpdate.next(event.target.value);
  }
  autoCompleteSearch() {
    this.headerService.getAutoSearchClientProfile(this.pageSize, 1, this.clientSearchVal).subscribe(res => {
      if (res && res.data && res.data.length > 0) {
        this.loadedCompleteData = false;
        if (this.clientSearchVal === '') {
          this.isSearchedEnabled = false;
        }
        this.pageNumber = 1;
        this.clientRecords = res.data;
        if (!this.clientRecords.some((clientRecord) => clientRecord.clientName === 'All')) {
          this.clientRecords.unshift(this.allClientInfo);
        }
        this.dropdown.value = JSON.parse(this.localStrService.getProfile());
        if (this.searchedDataLoadedComplete) {
          this.searchedDataLoadedComplete = false;
          this.attachScrollEvent();
        }
      } else {
        this.searchedDataLoadedComplete = true;
      }
      this.emptyDisplayMessage = this.sharedConstant.noRecords;
    });
  }

  logout() {
    this.authService.logOutUser();
  }
  resetFilterValue() {
    this.dropdown.containerViewChild.nativeElement.querySelector('p-dropdown input').blur();
    this.dropdown.resetFilter();
    if (this.isSearchedEnabled ) {
      this.isSearchedEnabled =  false;
      if (!this.loadedCompleteData && this.searchedDataLoadedComplete) {
        this.loadedCompleteData = false;
      }
    }
    if (this.isDropdownOpened && this.initialClientRecords.length > 0) {
       this.clientRecords = [...this.initialClientRecords];
       this.clientSearchVal = '';
    }
    if (this.clientRecords.findIndex(client => client.clientName === 'All') === -1) {
      this.clientRecords.unshift(this.allClientInfo);
    }
    this.clientRecords = this.removeDuplicateClients(this.clientRecords);
    this.selectedClient = JSON.parse(this.localStrService.getProfile());
    this.dropdown.value = JSON.parse(this.localStrService.getProfile());    
  }

  fetchClientProfile(isCreateClient?: boolean) {
    if (this.userPolicy.buPolicy && this.userPolicy.buPolicy.length > 0) {
      this.headerService.getAutoSearchClientProfile(this.pageSize, 1, '').subscribe(res => {
        const key = 'data';
        const userPolicy = this.authService.getUserPolicy();
        this.userRole = userPolicy.getValue().roleIds;
        this.sharedService.clientSummary = res[key];
        if (!(this.sharedService.initialClientRecords && this.sharedService.initialClientRecords.length > 0) || isCreateClient) {
          this.sharedService.initialClientRecords = res[key];
        }
        if (res[key].length === 1) {
          this.clientRecords = res[key];
          this.getClientDetails(this.clientRecords[0]);
          this.disableClientDropdown = true;
          this.sharedService.ClientCount = 1;
        } else {
          this.clientRecords = res[key];
          this.clientRecords = [this.allClientInfo].concat(this.clientRecords);
        }
        /*Update the localstorage with the latest value*/
        const clientProfile = this.localStrService.getProfile();
        if (clientProfile !== null) {
          const result = [...this.sharedService.initialClientRecords];
          result.unshift(JSON.parse(clientProfile));
          this.initialClientRecords = this.removeDuplicateClients(result);
          this.clientRecords = this.initialClientRecords;
          this.clientRecords.forEach((client) => {
            if (client.orgId === JSON.parse(clientProfile).orgId) {
              this.getClientDetails(client);
              this.selectedClient = JSON.parse(this.localStrService.getProfile());
              this.setClientProfile(this.selectedClient);
              this.dropdown.value = this.selectedClient;
            }
          });
        }
        if (!clientProfile || !this.isClientRecordPresent(this.clientRecords, JSON.parse(clientProfile))) {
          this.selectedClient = this.allClientInfo;
          this.localStrService.setProfile(JSON.stringify(this.selectedClient));
          this.setClientProfile(this.selectedClient);
        }
      });
    } else {
      this.headerService.getClientProfile().subscribe(res => {
        const key = 'data';
        const userPolicy = this.authService.getUserPolicy();
        this.userRole = userPolicy.getValue().roleIds;
        this.sharedService.clientSummary = res[key];
        this.loadedCompleteData = true;
        if (res[key].length === 1) {
          this.clientRecords = res[key];
          this.getClientDetails(this.clientRecords[0]);
          this.disableClientDropdown = true;
          this.sharedService.ClientCount = 1;
        } else {
          this.clientRecords = res[key];
          this.clientRecords = [this.allClientInfo].concat(this.clientRecords);
        }

        /*Update the localstorage with the latest value*/
        const clientProfile = this.localStrService.getProfile();
        if (clientProfile !== null) {
          this.clientRecords.forEach((client) => {
            if (client.orgId === JSON.parse(clientProfile).orgId) {
              this.getClientDetails(client);
              this.selectedClient = JSON.parse(this.localStrService.getProfile());
            }
          });
        }

        if (!this.localStrService.getProfile()) {
          this.selectedClient = this.allClientInfo;
          this.localStrService.setProfile(JSON.stringify(this.selectedClient));
          this.setClientProfile(this.selectedClient);
        }
      });
    }

  }

  fetchNextSetClientProfile(pageNumber: number) {
    const key = 'data';
    // for search case
    if (this.clientSearchVal) {
      this.headerService.getAutoSearchClientProfile(this.pageSize, pageNumber , this.clientSearchVal).subscribe(res => {
        if (res[key].length > 0) {
          Array.prototype.push.apply(this.clientRecords, res[key]);
          this.clientRecords.unshift(this.allClientInfo);
          this.clientRecords = this.removeDuplicateClients(this.clientRecords);
          this.dropdown.options = this.clientRecords;
          this.dropdown.value = JSON.parse(this.localStrService.getProfile());
          const index = (15 * (pageNumber - 1)) + 1;
          this.scrollToIndex(index);
        } else {
          // this.loadedCompleteData = true;
          this.searchedDataLoadedComplete = true;
        }
      });
    } else if (!this.isSearchedEnabled) {
      // for normal lazy case
      this.headerService.getAutoSearchClientProfile(this.pageSize, pageNumber, this.clientSearchVal).subscribe(res => {
        if (res[key].length > 0) {
          Array.prototype.push.apply(this.clientRecords, res[key]);
          Array.prototype.push.apply(this.sharedService.initialClientRecords, res[key]);
          if (this.isBUClientSelected) {
            this.clientRecords.unshift(this.allClientInfo);
          }
          this.clientRecords = this.removeDuplicateClients(this.clientRecords);
          this.initialClientRecords = this.clientRecords;
          this.dropdown.options = this.clientRecords;
          this.globalPageNo = pageNumber;
          const index = (15 * (pageNumber - 1)) + 2;
          this.scrollToIndex(index);
        } else {
          this.loadedCompleteData = true;
        }
      });
    }

  }

  onClientChange(e) {
    if (this.sharedService.isConfirmationRequiredClientChange) {
      const confirmObj = {
        header: this.sharedConstant.confirmationDialog.title,
        message: this.sharedConstant.clientChangeConfirmationMsg,
        acceptBtn: this.sharedConstant.confirmationDialog.acceptBtn,
        rejectBtn: this.sharedConstant.confirmationDialog.rejectBtn
      };
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(confirmObj, observer);
      }).subscribe(accept => {
        if (accept) {
          this.getClientDetails(e);
        } else {
          this.selectedClient = JSON.parse(this.localStrService.getProfile());
          this.localStrService.setProfile(JSON.stringify(this.clientObj));
        }
      });
    } else {
      this.getClientDetails(e);
    }
  }

  getClientDetails(e) {
    this.isDropdownOpened = false;
    this.clientObj = {
      clientName: e.clientName,
      k4ClientName: e.k4ClientName,
      orgId: e.orgId,
      clientId: e.clientId,
      linkType: e.linkType,
      liable407: e.liable407,
      blanket407: e.blanket407,
      index: e.index,
      businessUnit: e.businessUnit,
      charitable:e.charitable,
      custId: e.custId,
      tenB51: e.tenB51,
      externalAccess: e.externalAccess,
    };
    if (!this.isSubscribed) {
      this.sharedService.isClientChanged = !this.sharedService.isClientChanged ;
    } else {
      this.isSubscribed = false;
    }
    this.localStrService.setProfile(JSON.stringify(this.clientObj));
    if (e.clientName === 'All') {
      this.sharedService.selectedClientInfo.next('');
    } else {
      this.sharedService.selectedClientInfo.next(this.clientObj);
      this.isBUClientSelected = true;
    }
    this.setClientProfile(this.clientObj);
    this.selectedClient = JSON.parse(this.localStrService.getProfile());
    this.dropdown.value = JSON.parse(this.localStrService.getProfile());
    this.clientSearchVal = '';
    if (this.userPolicy.buPolicy && this.userPolicy.buPolicy.length > 0) {
      this.initialClientRecords = [...this.sharedService.initialClientRecords];
      this.initialClientRecords.unshift(this.clientObj);
      this.clientRecords = this.removeDuplicateClients(this.initialClientRecords);
      this.pageNumber = this.globalPageNo;
    }
  }

  setClientProfile(e) {
    this.emsSubject.setClientData(e);
    this.emtrService.currentOrg.next(e.orgId);
    this.emtrService.clientInformation.next(e);
    this.addlinkServ.currentOrg.next(e.orgId);
    this.addlinkServ.clientInformation.next(e);
    this.sharedService.currentOrg.next(e.orgId);
    this.sharedService.clientInformation.next(e);
  }

  stopDisabledAllClick(event, label) {
    if (label === this.allClientInfo.clientName && this.sharedService.specificClientView.getValue()) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
  isClientRecordPresent(clientRecords: any, currentRecord: any) {
    return clientRecords.some((clientRecord) => clientRecord.orgId === currentRecord.orgId);
  }
  removeDuplicateClients(result: any) {
    const uniq = {};
    return result.filter(obj => !uniq[obj.orgId] && (uniq[obj.orgId] = true));
  }
}
