<div>
  <ems-toast-message *ngIf="!uploadService.showErrorMsg && !(sharedService.errorMessage | async)" #toastMessage>
  </ems-toast-message>

  <ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>

  <sdps-notification type="error" class="sdps-m-top_none" *ngIf="uploadService.showErrorMsg">
    <div class="sdps-notification__text">
      <p *ngIf="uploadService.showErrorMsg" [innerHTML]="getConstant.uploadListPage.exportFailureMsg"></p>
    </div>
  </sdps-notification>

  <sdps-notification type="error" class="sdps-m-top_none" *ngIf="filterBtnDisableForsubmittedBy || filterBtnDisableForfileName ||
                    (uploadsListForm.get('fileName').value && uploadsListForm.get('fileName').invalid)">
    <div class="sdps-notification__text">
      <p [innerHTML]="addLinkConstant.pleaseCorrectFollowingError"></p>
      <ul class="sdps-navigation--horizontal__list error-list">
        <li *ngIf="filterBtnDisableForsubmittedBy || filterBtnDisableForfileName"
          [innerHTML]="getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg">
        </li>
        <li *ngIf="uploadsListForm.get('fileName').value && uploadsListForm.get('fileName').invalid"
          [innerHTML]="getSharedConstant.textFieldErrorMsg.uploadsFileNameError">
        </li>
      </ul>
    </div>
  </sdps-notification>

  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
    [innerHTML]="getConstant.uploadListPage.uploadLabel"></h2>

  <span class="sdps-m-horizontal_large sdps-m-bottom_medium sdps-display-inline-block client-selection-indicator"
    [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
    [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
  </span>

  <ems-table-record-count class="tbl-rec-count" [count]="totalUploads" [showTotal]="totalUploads"
    (emitter)="pageObject.pageNo = 1;pageObject.pageSize = $event;applyFilter()" [showHeaderIcons]="true"
    [showRecords]="paginationCount && paginationCount > 15">
  </ems-table-record-count>

  <form [formGroup]="uploadsListForm" class="reportListMenu">
    <p-table [columns]="uploadListTableCols" [value]="uploadsList" metaKeySelection="true" [lazy]="true"
      (onLazyLoad)="customSort($event)" [customSort]="true" [multiSortMeta]="multiSortMeta" sortMode="multiple"
      class="common-data-table manage-client-list-table custom-table-filter">
      <ng-template pTemplate="header" let-columns>
        <tr class="filter-header-input">
          <th class="table-checkbox-selection filter-icon">
            <a (click)="toggleFunction()" class="cursor">
              <em class="pi pi-filter"></em>
            </a>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="col.field !== 'uploadId'"
            [ngClass]="[(col.field === 'uploadId' || col.field === 'submittedDate') ? 'w-10-per' : '', ((col.field=== 'fileName' || col.field=== 'submittedBy') ? 'w-15-per' : ''), (col.field === 'uploadId') ? 'column-min-width' : '', (col.field=== 'fileName' || col.field=== 'submittedBy') ? 'column-max-width' : '']">
            {{col.header}}
            <p-sortIcon [field]="col.field" *ngIf="col.field ==='uploadId'"></p-sortIcon>
          </th>
        </tr>

        <tr *ngIf="this.toggle" class="filtered-row-input">
          <th></th>
          <th *ngFor="let col of columns" class="sdps-p-left_small">
            <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="uploadId"
              (keydown.enter)="applyFilter()" onkeypress="return (event.which >= 48 && event.which <= 57)"
              *ngIf="col.field === 'uploadId'">

            <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="fileName"
              *ngIf="col.field === 'fileName'" emsUploadsFileNamePatterncheck
              (keydown.enter)="!((uploadsListForm.get('fileName').value && uploadsListForm.get('fileName').invalid) || filterBtnDisableForsubmittedBy || filterBtnDisableForfileName) ? applyFilter() : ''"
              onkeydown="return (event.keyCode !== 13);"
              [ngClass]="{'invalidField': (filterBtnDisableForfileName || (uploadsListForm.get('fileName').value && uploadsListForm.get('fileName').invalid)), 'disabled': filterState === 'disable'}">

            <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="submittedBy"
              (keydown.enter)="!((uploadsListForm.get('fileName').value && uploadsListForm.get('fileName').invalid) || filterBtnDisableForsubmittedBy || filterBtnDisableForfileName) ? applyFilter() : ''"
              onkeydown="return (event.keyCode !== 13);" *ngIf="col.field === 'submittedBy'"
              [ngClass]="{'invalidField': filterBtnDisableForsubmittedBy, 'disabled': filterState === 'disable'}">

            <div class="timePickerForm" *ngIf="col.enum === 'dropdown'">
              <p-dropdown appendTo="body" [options]="uploadTypeDropdownValue" placeholder=" " formControlName="{{col.field}}"
                          class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label">
              </p-dropdown>
            </div>

            <div *ngIf="col.enum === 'multiselect'"
              class="col-2 emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size request-list-filter-multiselect">
              <p-multiSelect appendTo="body" formControlName="{{col.field}}"
                [options]="getConstant.uploadListPage.uploadsListFilter[col.field]" [filter]="true" [defaultLabel]="' '"
                [displaySelectedLabel]="true" [maxSelectedLabels]="1" [showHeader]="true" [filterPlaceHolder]="'All'"
                [filter]="true">
              </p-multiSelect>
            </div>

            <div *ngIf="col.enum === 'dateRange'">
              <ems-date-range-filter #range [disableForm]="filterState === 'disable'"
                (submittedDate)="getSelectedDate($event);" [limitCustomRangeTo6Months]="true"></ems-date-range-filter>
            </div>

            <div *ngIf="col.field === 'action'" class="sdps-p-right_none w-200-px">
              <button class="buttons button-filter"
                [innerHTML]="getConstant.uploadListPage.uploadsListFilter.clearButton"
                [disabled]="!uploadsListForm.dirty && !isSortingChanged"
                (click)="isClearBtnClicked = true;filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); clearSuccessAndErrorMessages();"></button>
              <button class="buttons button-save button-filter"
                [disabled]="(uploadsListForm.get('fileName').value && uploadsListForm.get('fileName').invalid) || filterBtnDisableForsubmittedBy || filterBtnDisableForfileName"
                (click)="applyFilter()" [innerHTML]="getConstant.uploadListPage.uploadsListFilter.applyButton"></button>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length + 1" class="sdps-text-center"
            [innerHTML]="getConstant.manageUserPage.noRecords"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td class="table-checkbox-selection filter-icon"></td>
          <ng-container *ngFor="let col of columns">
          <td *ngIf="col.field !== 'action'"
            [ngClass]="[(col.field === 'uploadId' || col.field === 'submittedDate') ? 'w-10-per' : '', ((col.field=== 'fileName' || col.field=== 'submittedBy') ? 'w-15-per' : ''), (col.field=== 'clientName' || col.field=== 'fileName' || col.field=== 'submittedBy') ? 'word-break' : '', (col.field === 'action' || col.field === 'uploadId') ? 'column-min-width' : '', col.field=== 'fileName' || col.field=== 'submittedBy' ? 'column-max-width' : '']">
            <span *ngIf="col.field === 'uploadId'" [innerHTML]="rowData[col.field]"></span>
            <span *ngIf="col.field === 'clientName'" [innerHTML]="rowData[col.field]"></span>
            <span *ngIf="col.field === 'fileName'" [innerHTML]="rowData[col.field]"></span>
            <span *ngIf="col.field === 'uploadType'">
              <a href="javascript:void(0)" [innerHTML]="rowData[col.field]"
                (click)="navigateTo(rowData[col.field])"></a>
            </span>
            <span *ngIf="col.field === 'submittedBy'" [innerHTML]="rowData[col.field]"></span>
            <span *ngIf="col.field === 'status'" class="status-column">
              <span class="status sdps-p-around-xx-large"
                *ngIf="rowData['status'] !== getConstant.uploadListPage.processingStatus"
                ngClass="{{rowData[col.field] | lowercase}}">
              </span>
              <span class="status-word-wrap sdps-m-left_x-small"
                *ngIf="rowData['status'] !== getConstant.uploadListPage.processingStatus"
                [innerHTML]="rowData[col.field] | titlecase"></span>
              <!-- <p-progressBar [value]="rowData['progressValue']" [ngClass]="{'custom-progressbar' : rowData['progressValue'] === 0}"
                *ngIf="rowData['status'] === getConstant.uploadListPage.processingStatus"></p-progressBar> -->
                <sdps-progress-indicator sdps-id="loader-determinate" *ngIf="rowData['status'] === getConstant.uploadListPage.processingStatus"
                 [attr.helper-text]="rowData['progressValue'] + '%'" [value]="rowData['progressValue']" max="100"
                 sdps-aria-describedby="loading-zone" sr-value-suffix="percent"></sdps-progress-indicator>
            </span>
            <span *ngIf="col.field === 'submittedDate'"
              [innerHTML]="rowData[col.field]? moment(rowData[col.field]).format('MM/DD/YYYY'): ''"></span>
          </td>
          </ng-container>
          <td>
            <div class="action-menu-dropdown">
              <button type="button" class="cursor sdps-p-around_xx-small"
                      [disabled]="rowData['status'] === this.getConstant.uploadListPage.processingStatus"
                      (click)="showActionMenu=true; uploadId=rowData['uploadId']; formMenuItem(rowData);"
                      [ngClass]="{'active-menu':uploadId===rowData['uploadId'], 'disable-overlay-menu': rowData['status'] === this.getConstant.uploadListPage.processingStatus }">
                <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
              </button>
              <p-menu [model]="actionMenu"
                      *ngIf="uploadId===rowData['uploadId']"
                      class="menu-dropdown" (click)="onActionMenuClick($event, rowData)"></p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <p-paginator #p *ngIf="paginationCount && paginationCount > 15" class="common-pagination" [rows]="pageObject.pageSize" [totalRecords]="paginationCount"
    (onPageChange)="paginate($event);"></p-paginator>
</div>

<app-uploads-modal #uploadsList *ngIf='uploadsList && uploadsList.length > 0' (exportID)=getExportID($event);>
</app-uploads-modal>
