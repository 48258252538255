<form [formGroup]="empListSubHeaderForm">
  <div class="expanded-row-filter sdps-p-bottom_medium">
    <div *ngFor="let col of empAccSubHeaderCols">
      <!-- Header section of the employees/participants sub-header -->
      <div class="timePickerForm sdps-p-left_small sdps-p-bottom_small sdps-p-top_small">
        <span *ngIf="col.header !== 'Show Records'">{{col.header}}</span>
      </div>

      <!-- Group/Plan Name dropdown -->
      <div class="timePickerForm on-scroll emtr-dropdown mutual-fund-asset-group-dropdown group-plan-name-dropdown sdps-p-left_small"
           *ngIf="col.enum === 'dropdown' && col.field === 'empGroupName'">
        <p-dropdown #dropdown name="{{col.field}}" [filter]="true" [options]="grpOrPlanNameList" [(ngModel)]="nameDropdownInpVal" optionValue="value"
                    (ngModelChange)="groupNameUpdated()" [ngModelOptions]="{standalone: true}" [resetFilterOnHide]="isClearClicked"
                    (keyup)="searchedText($event, 'groupName')" (onShow)="clearSearch(dropdown)" (click)="isClearClicked = nameDropdownInpVal !== ''; sharedService.selectedGroupAndPlanNameInEmpGroup = nameDropdownInpVal">
          <ng-template let-nameList pTemplate="item">
            <div class="ui-helper-clearfix display-image scrollHere">
              <span>{{nameList.label}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
</form>
