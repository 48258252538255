import { entitlementConst } from '@shell_components/constants/entitlementConstants';

export const ShellConstant = {
  reqDetailPollingInterval: 10000 /*time in ms*/,
  clientDrpLabel: 'Client:',
  accessDenied: 'Error: Access Denied',
  fromDate: 'From Date',
  toDate: 'To Date',
  invalidDate: 'Please enter a valid date MM/DD/YYYY',
  accessDeniedMessage: 'You do not have permission to access this page.',
  noCientSelected: 'Please Select a Client to Continue.',
  invalidFutureDateEntered: 'From Date or To Date cannot be a future date.',
  refreshMsg: 'Please refresh to go to Designated Broker Application',
  redirectMsg: ' or click on ',
  link: 'link',
  allClientInfo: {
    clientName: 'All',
    k4ClientName: 'All',
    orgId: 0,
    clientId: null,
    custId: '',
    businessUnit: ''
  },
  confirmationMessage: {
    cancelBtnClickMsg: 'Are you sure you want to Cancel? This will cancel your session and unsaved changes will be lost.',
    menuClickMsg: 'You are about to leave this page and unsaved changes will not be saved. Are you sure you want to leave and Cancel?'
  },
  reports: {
    characterLimit: 'Char. Limit:',
    rangeErrorMessage: 'The Maximum Lookback period for the report is ',
    rangeErrormessagePart2: 'Please adjust your report dates to be within ',
    reportActionMenu: {
      reportDetails: 'Report Details',
      downloadReport: 'Download Report'
    },
    allGroupsInfo: {
      description: null,
      empAccounts: null,
      employeeGroupAssignInfo: null,
      id: 1676,
      name: 'All Groups',
      namePlan: 'All Plans',
      type: 'ORG',
      groupNameAll: 'All',
      groupName: 'groupName'
    },
    EmployeeEmployeeGroupAssociation: {
      routerLink: 'employee-group',
      name: 'Employee / Employee Group Association'
    },
    EmpGroupEmpReport: {
      routerLink: 'employee-group',
      name: 'Employee / Employee Group Association'
    },
    RulesDetails: {
      routerLink: 'rule-details',
      rulesDetails: 'Rules Details',
      runReportFor: 'ruleReport',
      passReportName: 'RuleReport',
      passReportNameSnapShot: 'RuleSnapShotReport',
      all: 'All',
      individualSecurity: 'Individual Security',
      securityType: 'Security Type',
      individualSecurityCaps: 'INDSEC',
      securityTypeCaps: 'SECTYP',
      active: 'Active',
      scheduled: 'Scheduled',
      draft: 'Draft',
      expired: 'Expired',
      pdf: 'PDF',
      xls: 'XLS',
      reportPublic: 'Make Report Public',
      ruleStatus: 'Rule Status',
      appliesTo: 'Applies To',
      ruleType: 'Rule Type',
      subTypeTxt: 'Sub Type',
      symbol: 'Symbol',
      cusip: 'CUSIP',
      outputFormat: 'Output Format',
      inValidPastDateEntered: 'From Date or To Date cannot be more than 90 days in the past.',
      snapShot: 'Snapshot',
      historyTxt: 'History',
      fundFamilyTxt: 'Fund Family',
      fundFamilyCaps: 'FNDFMLY',
    },
    TradeAttemptReport: {
      routerLink: 'trade-attempts',
      name: 'Trade Attempts Report'
    },
    TradeAttempts: {
      routerLink: 'trade-attempts',
      name: 'Trade Attempts'
    },
    EmployeeAccountDetails: {
      routerLink: 'employee-account',
      name: 'Employee Account Details'
    },
    ParticipantAccountDetails: {
      routerLink: 'participant-account',
      name: 'Participant Account Details'
    },
    RuleReport: {
      name: 'Rules Details',
      routerLink: 'rule-details'
    },
    OpenOrderReport: {
      routerLink: 'open-order',
      name: 'Open Order Details'
    },
    'Trade Attempts': {
      routerLink: 'trade-attempts',
      name: 'Trade Attempts'
    },
    'Employee Account': {
      routerLink: 'employee-account',
      name: 'Employee Account Details'
    },
    'Participant Account': {
      routerLink: 'participant-account',
      name: 'Participant Account Details'
    },
    'Employee Group': {
      routerLink: 'employee-group',
      name: 'Employee / Employee Group Association'
    },
    ParticipantPlanAssociation: {
      routerLink: 'participant-plans',
      name: 'Participant / Plan Association'
    },
    Plan: {
      routerLink: 'participant-plans',
      name: 'Participant / Plan Association'
    },
    Employee: {
      routerLink: 'employee-group',
      name: 'Employee / Employee Group Association'
    },
    Rule: {
      name: 'Rules Details',
      routerLink: 'rule-details'
    },
    'Open Orders': {
      routerLink: 'open-order',
      name: 'Open Order Details'
    },
    'ADDLink Usage': {
      routerLink: 'addlink-usage',
      name: 'ADDLink Usage',
    },
    'ADDLink Requests': {
      routerLink: 'addlink-requests',
      name: 'ADDLink Requests',
    },
    ADDLinkRequests: {
      routerLink: 'addlink-requests',
      name: 'ADDLink Requests',
    },
    'Rule Audit': {
      routerLink: 'rule-audit',
      name: 'Rule Audit',
    },
    RuleAudit: {
      routerLink: 'rule-audit',
      name: 'Rule Audit',
      outOfRange: 'Date range should be between three months.'
    },
    OpenOrderDetails: {
      routerLink: 'open-order',
      inValidPastDateEntered: 'From Date or To Date cannot be more than 60 days in the past.',
      openOrder: 'Open Order Details',
      buy: 'Buy',
      sell: 'Sell',
      shortSell: 'Short Sell',
      shortSellCaps: 'SHORT_SELL',
      runReportFor: 'openOrder',
      passReportName: 'OpenOrderReport',
      tradeType: 'Trade Type'
    },
    ADDLinkUsage: {
      routerLink: 'addlink-usage',
      source: 'Source',
      status: 'Status',
      name: 'ADDLink Usage',
      submitterType: 'Submitter Type'
    },
    ADDLinkRequestsReport: {
      routerLink: 'addlink-requests',
      requestStatus: 'Request Status',
      action: 'Action',
      linkTxt: 'Link',
      delinkTxt: 'Delink',
      name: 'ADDLink Requests'
    },
    ADDLinkReport: {
      routerLink: 'addlink-usage',
      name: 'ADDLink Usage',
    },
    presetReports: {
      category: 'Category',
      presetReportsLabel: 'Preset Reports',
      reportCategoryDropDown: ['All Reports', 'ADDLink Reports', 'EMTR Reports', 'Scheduled Reports', 'Recently Deleted Reports', 'Recent Reports'],
      employeeIDAssociationDropDown: [{ value: '', label: 'All' }, { value: 'ASSIGNED', label: 'Assigned' }, { value: 'UNASSIGNED', label: 'Unassigned' }],
      tradeResultsDropDown: [{ value: 'Both', label: 'Both' }, { value: 'Blocked', label: 'Blocked' }, { value: 'Allowed', label: 'Allowed' }],
      employeeIDAssociation: 'Employee ID Association',
      employeeGroupName: 'Employee Group Name',
      employeeStatus: 'Employee Status',
      reportName: 'Report Name',
      from: 'From',
      to: 'To',
      employeeID: 'Employee ID',
      accountNumber: 'Account Number',
      employeeLastName: 'Employee Last Name',
      participantLastName: 'Participant Last Name',
      masterAccountNumber: 'Master Account',
      accountHolderLastName: 'Account Holder Last Name',
      active: 'Active',
      inactive: 'Inactive',
      tradeResult: 'Trade Result',
      ruleName: 'Rule Name',
      tradeSymbol: 'Symbol',
      cusipSymbol: 'CUSIP',
      badRequest: 'Bad Request',
      reportNames: {
        tradeAttemptTxt: 'Trade Attempts Report',
        employeeAssociationTxt: 'Employee Account Details',
        employeeGrpAssociationTxt: 'Employee / Employee Group Association',
        tradeAttemptsReportName: 'TradeAttemptReport',
        employeeAssociationReportName: 'EmployeeAccountDetails',
        employeeGrpAssociationReportName: 'EmpGroupEmpReport',
        employeeGrpAssociationSnapshotReportName: 'EmployeeGroup',
        addlinkUsageReportName: 'ADDLinkReport',
        addlinkUsageTxt: 'addLinkReport',
        addlinkRequestReportName: 'ADDLinkRequestListReport',
        addlinkRequestTxt: 'requestListReport',
        participantAssociationReportName: 'ParticipantAccountDetails',
        participantAssociationTxt: 'Participant Account Details',
        ruleAuditReportName: 'RBSRuleAuditReport',
       // ruleAuditTxt: 'RBSRuleAuditReport'
      },
      reportLandingPageMessage: 'Report request has been submitted. Please refresh the page and review your report.',
      employeeIdErrorMsg: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9,- . \' (apostrophe)',
      employeeGrpReportsNamesErrorMsg: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, ., -,_, \' (apostrophe).',
      empAccountErrMsg: 'Please enter a value that is numeric for Employee Account.',
      masterAccountErrorMsg: 'Please enter a value that is numeric for Master Account.',
      employeeErrorMsgForAllGroup: 'Please enter a value in Employee Id or Account Number or Employee LastName or Account Holder LastName to run the report.',
      refreshTxt: 'Refresh',
      runReportSuccessAndRedirectMsg: 'Report request has been submitted. You will be redirected to the Reports tab to view the status of your report.',
      enterGroupName: 'Group Name',
      enterPlanName: 'Plan Name'
    },
    sendReportAPI: {
      snapshotAPI: 'snapshot',
      empGroupTxt: 'empgroup',
      tradeAttempt: 'tradeAttempt',
      empAccount: 'empAcctEmpIdReport',
      empGroup: 'empAcctEmpGrpReport',
    },
    reportsListPage: {
      reportsTitls: 'Reports',
      reportsInfoMsg: 'Reports from the last six months are provided.',
      reportDetailsModalTitle: 'Report Details',
      reportIdTitle: 'Report ID: ',
      reportTypeTitle: 'Report Type: ',
      clientNameText: 'Client Name',
      submittedByText: 'Submitted By',
      submittedDateText: 'Submitted Date',
      reportCriteriaText: 'Report Criteria',
      reportListDropdownMenuTxt: [{ label: 'History', value: 'History' }, { label: 'Snapshot', value: 'Snapshot' }],
      reportListFilter: {
        status: [{ value: 'Complete', label: 'Complete' }, { value: 'Error', label: 'Error' }, {
          value: 'Processing',
          label: 'Processing'
        }],
      }
    }
  },
  manageUserPage: {
    manageUsers: 'Manage Users',
    addNewUser: 'Setup New User',
    delete: 'Delete',
    cancel: 'Cancel',
    deleteMsgConfirmation: '  Are you sure you want to delete?',
    enterUserinfo: 'Enter User Info',
    userType: 'User Type:',
    internal: 'Internal',
    firstName: 'First Name',
    lastName: 'Last Name',
    opaqueId: 'Schwab Id',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number (123-456-7890)',
    phoneNumberNonUS: 'Phone Number',
    selectRole: 'Select Role:',
    businessUnits: 'Business Unit:',
    save: 'Save',
    noRecords: 'No Records Found.',
    defaultRecordSize: 15,
    userDetails: 'Edit User Info',
    viewUserDetails: 'View User Info',
    invalidFirstName: 'Invalid First Name',
    invalidEmailAddress: 'Invalid Email',
    invalidSchwabId: 'Invalid SchwabId',
    invalidLastName: 'Invalid Last Name',
    cancelMsgConfirmation: 'You are about to leave this page and unsaved changes will not be saved. Are you sure you want to leave and Cancel?',
    external: 'External',
    sendInvite: 'Send Invite',
    recordLastUpdated: 'Last Updated:',
    status: 'Status',
    resendInvite: 'Resend Invite',
    wasSuccuessfullyInvited: 'was successfully invited.',
    wasSuccuessfullyModified: 'was successfully modified.',
    wasSuccuessfullyCreated: 'was added successfully.',
    wasSuccuessfullyUpdated: 'was successfully updated.',
    resendSuccessfully: 'invite has been resent successfully',
    user: 'User',
    roles: 'Roles: ',
    rolesLabel: 'Roles ',
    userTypeText: 'User Type:',
    sendInviteAddMore: 'Send Invite & Add more',
    active: 'Activate',
    alreadyActive: 'is already active.',
    selectBUOrAssignClientToUser: 'Select BU or Assign Client(s) to User:',
    assignClientToUser: 'Assign Client(s) to User:',
    bu: 'BU',
    customerID: 'Customer ID',
    businessUnitName: 'Business Unit Name',
    errorMsg: {
      phoneNumbershouldBeNumeric: 'Invalid Phone Number. Please use valid characters from 0-9.',
      client: 'Client ',
      pleaseCorrectFollowingError: 'Please correct following error.',
      duplicateClientSelected: ' has already been selected. Please select a different Client to Assign to User.',
      clientAccess: 'You do not have permission to edit the user.'
    },
    statusField: {
      createdBy: 'Created By',
      createdDate: 'Created Date',
      inviteDate: 'Invite Date',
      updatedBy: 'Updated By',
      updatedDate: 'Updated Date'
    },
    manageUserFilter: {
      userType: [{value: 'All', label: 'All'}, {value: 'Internal', label: 'Internal'}, {value: 'External', label: 'External'}],
      status: [{value: 'Active', label: 'Active'}, {value: 'Inactive', label: 'Inactive'}, {value: 'Pending', label: 'Pending'}],
    },
    managerUserFilterErrorMsgs: {
      minCharLimitErrorMsg: 'Please enter at least 2 characters before filtering.'
    },
    dbs: 'DBS',
    sps: 'SPS',
    rbs: 'RBS',
    dbsAddlink: 'DBSADDLink',
    dbsEmtr: 'DBSEMTR',
    spsAddlink: 'SPSADDLink',
    spsEmtr: 'SPSEMTR',
    emtr: 'EMTR',
    addlink: 'ADDLink',
    landingPageURL: '/ems/admin/manage-users',
    userName: 'User Name',
  },

  manageClients: {
    mangeClientList: {
      setupNewClient: 'Setup New Client',
      manageClientsLabel: 'Manage Clients',
      bulkUpload: 'Bulk Upload',
      setupWasSuccessfullySaved: ' Setup was successfully saved.',
      setupWasSuccessfullyUpdated: ' Setup was successfully updated.',
      internalAcsTyp: 'Internal',
      externalAcsTyp: 'External/Self-Service',
      applications: [{value: 'All', label: 'All'}, {value: 'ADDLink', label: 'ADDLink'}, {value: 'EMTR', label: 'EMTR'}],
      linkType: [{value: 'All', label: 'All'}, {value: 'Dual', label: 'Dual'}, {value: 'Standard', label: 'Standard'}],
      businessUnit: [{value: 'All', label: 'All'}, {value: 'DBS', label: 'DBS'}, {value: 'RBS', label: 'RBS'}, {value: 'SPS', label: 'SPS'}],
      accessType: [{value: 'All', label: 'All'},  {value: 'Y', label: 'External/Self-Service'}, {value: 'N', label: 'Internal'}]
    },
    clientDetailsPage: {
      setupClientPage: 'Enter Client Details',
      editClientPage: 'Edit Client Details',
      masterAccounts: 'Master Account(s):',
      label: 'Label',
      custId: 'Cust ID',
      k4Master: 'K4 Master',
      clientName: 'Client Name',
      registrationName: 'Registration Name',
      customName: 'CUSTOM NAME',
      enterCustomName: 'Enter Custom Name',
      clientRegName: 'Client / Registration Name',
      linkType: 'Link Type',
      applications: 'Applications',
      businessUnit: 'Business Unit',
      sctClientId: 'SCT Client ID:',
      authorizedAgent: 'Authorized Agent',
      firstName: 'First Name:',
      lastName: 'Last Name:',
      addAgent: 'Add Agent',
      newClientLabels: {
        addlink: 'ADDLink',
        emtr: 'EMTR'
      },
      emtrConversionStatus: '(Pending Trade Rules Conversion)',
      accessType: 'Access Type',
      specializedLinking: 'Specialized Linking',
      clientType: 'Client Type',
      charitableClient: 'Charitable Client',
      charitableAccounts: 'Allow Charitable Accounts',
      external: 'External/Self-Service',
      commCode: 'Comm Code:',
      liable: '407 Liable',
      blanketLetterOnFile: 'Blanket 407 Letter on File',
      indexKey: 'Index Key',
      authorizedAgentNameAlreadyExists: 'Authorized Agent Name Already Exist.  Please check the name and add again',
      delete: 'Delete',
      confirmation: 'Confirmation',
      deleteMsgConfirmation: 'Are you sure you want to delete?',
      errorMsg: {
        custIDIsInvalid: 'Cust ID is invalid. Please enter a valid Cust ID',
        k4MasterIsInvalid: 'K4 Master is invalid. Please enter a valid K4 Master',
        k4MasterInvalidChar: 'K4 Master is invalid. Please enter numeric values 0-9 and the account number cannot start with 0',
        masterAccountInvalidChar: 'Master Account is invalid. Please enter a valid Master Account.',
        custIDAlreadyExist: 'Cust ID already exist in ADDLink/EMTR. Please enter a Cust ID that has not been previously setup for ADDLink/EMTR',
        custIDLimit: 'Cust ID is invalid. Cust Id with maximum of 10 digits is allowed',
        k4MasterAlreadyExist: 'K4 Master already exist in ADDLink/EMTR. Please enter a K4 Master that has not been previously setup for ADDLink/EMTR',
        masterAccountAlreadyExist: 'Master Account already exist in ADDLink/EMTR. Please enter a Master Account that has not been previously setup for ADDLink/EMTR',
        clientIDAlreadyExist: 'Client ID already exist in ADDLink/EMTR. Please enter a Client ID that has not been previously setup for ADDLink/EMTR',
        invalidCharacterEnteredNumericValue: 'Invalid Character Entered - Please enter numeric values 0-9',
        invalidCharacterEnteredAlphabeticValue: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, ., -,_, \' (apostrophe), , (comma) and space. The name cannot start with special characters, spaces, any number, or end with special characters.',
        invalidCharacterEnteredAlphaNumericValue: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9',
        moreThanOneFASelected: 'Only one FA selection is allowed.  Please review your Master Accounts to make sure there is only one FA selected.',
        noFAorSLMasterAccountSelected: 'FA or SL Selection Required. Please Select FA or SL for the selected Master Account.',
        invalidCharacterForName: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, +, -, _, (), [], &, /, .(dot), \' (apostrophe), ,(commas)',
        moreThanOne10b5Selected: 'Only one 10b5-1 selection is allowed.  Please review your Master Accounts to make sure there is only one 10b5-1 selected.',
        masterAcctNumShouldContain8Digits: 'Master Account Number should contain 8 digits.',
        empAcctNumShouldContain8Digits: 'Account Number should contain 8 digits.',
        emtrConversionCannotBeDone: 'Client cannot be converted back to EMTR again.'
      },
      responseMsg: {
        custIdExist: 'CustID Already Exist'
      },
      masterAccountDropdown: [{ value: 'FA', label: 'FA' }, { value: 'ADDLink', label: 'SL - ADDLink' }, { value: 'EMTR', label: 'SL - EMTR' }, { value: '10b5-1', label: 'SL - 10b5-1' }],
      k4MasterAccountDropdown: [{ value: 'FA', label: 'FA' }, { value: 'K4', label: 'K4 - EMTR' }, { value: 'SL', label: 'SL - EMTR' }],
      masterAccountDropdownWithNo10b5: [{ value: 'FA', label: 'FA' }, { value: 'ADDLink', label: 'SL - ADDLink' }, { value: 'EMTR', label: 'SL - EMTR' }],
      exception: 'Exception',
      dbs: 'DBS'
    }
  },

  uploadListPage: {
    uploadLabel: 'Uploads',
    processingStatus: 'Processing',
    complete: 'COMPLETE',
    started: 'STARTED',
    received: 'RECEIVED',
    exportSucessMsg: 'Export request has been submitted and will be accessible once complete.',
    exportFailureMsg: 'Error while processing the Export. Please try again.',
    fileExportedMsg: 'File has already been exported.',
    message: 'Upload Details Retrieved Successfully.',
    uploadActionMenu: {
      downloadFile: 'File Download',
      detailsReport: 'Details Report',
      exportExcel: 'Export Excel'
    },
    uploadsListFilter: {
      status: [{value: 'Complete', label: 'Complete'}, {value: 'Error', label: 'Error'}, {
        value: 'Processing',
        label: 'Processing'
      }],
      clearButton: 'Clear',
      applyButton: 'Apply'
    },
  },

  workBenchChart: {
    overrideBgColorStartDate: [
      '#1D3F5A',
      '#1A5F8A',
      '#19A0CD',
      '#19A0CD5C'
    ],
    overrideBgColorEndDate: [
      '#A08D40',
      '#C7BD61',
      '#E1DCAA',
      '#E1DCAA5C'
    ],
    restrictionBgColorStartDate: [
      '#9D2F3C',
      '#D63A40',
      '#F67F93',
      '#F67F9365'
    ],
    restrictionBgColorEndDate: [
      '#031010',
      '#0C474F',
      '#008A8B',
      '#1FB9B5'
    ],
    bulkUploadBgColor: [
      '#7E57C2',
      '#512DA8',
    ],
    dayLabels: ['3 Days', '2 Days', '1 Day', 'Today'],
    weekLabels: ['6 - 7 Days', '3 - 5 Days', '1 - 2 Days', 'Today'],
    quickLinkTxt: 'Quick Links',
    lastUpdatedTxt: 'Last Updated 25 Secs ago',
    lastRefreshedTxt: 'Last Refreshed',
    comingSoonTxt: 'Coming Soon',
    addLink: {
      addlinkTxt: 'ADDLink',
      requestStatusTxt: 'Request Status',
      accNotLinkedTXT: 'Accounts Not Linked',
      accLinkedTxt: 'Total Accounts Currently Linked',
      masterAcc: {
        masterAccTxt: 'SL Master Account(s)',
        count: 84,
      },
      masterSLAcc: {
        masterSLAccTxt: 'Master Account',
        masterSLAccSubTxt: 'FA + SL Accounts',
        count: 108,
      },
      authorizedAgentTableHeader: [{ field: 'first', header: 'First Name' }, { field: 'last', header: 'Last Name' }],
      authorizedAgents: 'Authorized Agents',
      bulkUploadChart: ['Imported and not Linked', 'Imported and Linked'],
      requestStatus: {
        onHold: 'On Hold',
        validationMismatch: 'Validation Mismatch',
        DBSAction: 'DBS Action Required',
        DBSActionNeeded: 'DBS Action Needed',
        requireIndexing: 'Require Indexing',
        clientCentralLinks: 'Client Central Links',
        clientCentralDelinks: 'Client Central Delinks'
      }
    },

    rules: {
      emtrTxt: 'Schwab Trade Rules Overview',
      restrictionTxt: 'Restrictions',
      overrideTxt: 'Overrides',
      startDateTxt: 'Start Date',
      endDateTxt: 'End Date',
      noOfRulesStartDateTxt: 'Number of rules with start date',
      noOfRulesEndDateTxt: 'Number of rules with end date',
      fromTodayTxt: 'from today',
      RulesLinks: [
        { name: 'New Rule/Override', link: 'ems/emtr/rules', hasEntitlement: entitlementConst.navUUID.emtrAccess.manageRule.uuid, gutTrack: 'linkid-lc_ql_newrule' },
        { name: 'Rules Bulk Upload', link: 'ems/emtr/rules/rules-bulk-upload', hasEntitlement: entitlementConst.navUUID.emtrAccess.manageRule.uuid, gutTrack: 'linkid-lc_ql_ruleupload' },
        { name: 'Add Employee Group', link: 'ems/emtr/manage-employee-groups/employee-group-list', hasEntitlement: entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, gutTrack: 'linkid-lc_ql_newemployeegroup' },
        { name: 'Modify an Employee', link: 'ems/emtr/manage-employees', hasEntitlement: entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, gutTrack: 'linkid-lc_ql_modifyemployee' },
        { name: 'Employee Bulk Upload', link: 'ems/emtr/manage-employees/emp-bulk-upload', hasEntitlement: entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, gutTrack: 'linkid-lc_ql_employeeupload' },
      ],
      RBSRulesLinks: [
        { name: 'New Rule/Override', link: 'ems/emtr/rules', hasEntitlement: entitlementConst.navUUID.emtrAccess.manageRule.uuid, gutTrack: 'linkid-lc_ql_newrule' },
        { name: 'Add Plan', link: 'ems/emtr/manage-employee-groups/employee-group-list', hasEntitlement: entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, gutTrack: 'linkid-lc_ql_addplan' },
        { name: 'Manage Participant', link: 'ems/emtr/manage-employees', hasEntitlement: entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, gutTrack: 'linkid-lc_ql_manageparticipant' },
      ],

      chartRuleStatus: ['Scheduled', 'Active', 'Expired', 'Draft'],
      pieChart: 'Pie Chart',
      barChart: 'Bar Chart',
      rulesActivity: 'Rules Activity',
      rulesTxt: 'Schwab Trade Rules<sup>&#174</sup>',
      ruleTxt: 'Rule',
      linkedAcc: {
        linkedAccTxt: 'Linked Accounts',
        linkedAccSubTxt: 'Currently',
        count: '07'
      },
      sps: {
        spsTxt: 'SPS Clients',
        spsSubTxt: 'Clients',
        count: '09'
      },
      override: {
        overrideTradeTxt: 'Override Trades',
        overrideTradeSubTxt: 'Allowed Last Week',
        count: '238'
      },
      attemptTrade: {
        attemptTradeTxt: 'Attempt Trades',
        attemptTradeSubTxt: 'Blocked Last Week',
        count: '421',
      },
      noDataTxt: 'No Rules are Starting or Ending within 7 days',
    }
  },
  deactivate: {
    deactivate: 'Deactivate',
    deactivateMsgConfirmation: 'Are you sure you want deactivate user ',
    questionMark: '?',
    userName: 'Username ',
    deactivated: ' has been deactivated.',
  },
  terms: {
    acceptBtnTxt: 'Accept',
    declineBtnTxt: 'Decline',
    modalTitle: 'Terms & Conditions',
    internalUserNav: 'https://schweb.schwab.com/home/index.shtml',
    termsMsgConfirmation: 'I have read the Terms and Conditions'
  },
  sessionConstants: {
    externalUserTimeoutInminutes: 5,
    minutesTxt: 'minutes',
    calendarFormat: 'YYYY-MM-DDTHH:mm:ss'
  },
  accessDeniedTooltipMessage: 'Selected client does not have permission to access this page.',
  citiClient: {
    submittedBy: 'FEED'
  },
  rbsBUText: 'RBS',
  k4Text: 'K4',
  reportTypeTxt: {
    openOrderDetailsTxt: 'Open Order Details',
    openOrderTxt: 'Open Orders',
    participationPlanAssociationTxt: 'Participant / Plan Association',
    participantPlan: 'Plan',
    empAccountDetailsTxt: 'Employee Account Details',
    empAccountTxt: 'Employee Account',
    participantAccountDetailsTxt: 'Participant Account Details',
    participantAccountTxt: 'Participant Account',
    empGroupAccountAssTxt: 'Employee / Employee Group Association',
    empGroupAccountTxt: 'Employee Group',
    rulesDetailsTxt: 'Rules Details',
    ruleTxt: 'Rule'
  },
};


